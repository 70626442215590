// IMPORTS
@import (once) "/node_modules/nouislider/dist/nouislider.css";

@import (reference) "../settings.less";

// SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

// TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    #tree {
        border: 1px solid @main-color;
        position: relative;
        padding-bottom: 12px;

        &:before {
            content: 'Kategorie';
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            line-height: 1.2;
            letter-spacing: 0.04em;
            padding: 24px 0 12px 20px;
            display: block;
        }

        > nav {
            padding: 0 20px 12px 20px;
        }


        li {
            position: relative;

            ul {
                display: none;
            }
        }

        a {
            position: relative;
            color: @header-color;
            display: block;
            padding-block: 12px;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }
        }

        li.t0 {
            border-bottom: 1px solid @border-color;

            &:last-child {
                border-bottom: none;

                > a {
                    padding-bottom: 0 !important;
                }
            }

            &.parent > a {
                padding-right: 30px;
            }

            &.downarrow {

                > a {
                    border-bottom: 1px solid @border-color;
                    padding-bottom: 12px;
                }

                > button:after {
                    transform: scaleY(-1);
                }

                > ul {
                    display: block;
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                width: 30px;
                height: 48px;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: @header-color;

                &:after {
                    content: "\e91c";
                    font-family: icomoon;
                }
            }

            li:last-child {
                margin-bottom: 0 !important;
            }
        }

        li:not(.t0) {

            > a {
                padding-block: 6px;
            }

            &:first-child > a {
                margin-top: 6px;
            }

            &:last-child > a {
                margin-bottom: 6px;
            }
        }

        li.t1 {
            margin-left: 16px;
        }

        li.t2 {
            margin-left: 24px;
        }

        li.t3 {
            margin-left: 32px;
        }

        li.selected,
        li:has(.selected) {

            > a {
                font-weight: 700;
            }
        }

        small {
            display: none;
        }
    }
}

// CATEGORIES VIEW
.categories-view-wrapper {
    display: flex;
    flex-direction: column;
    gap: calc(0.5 * var(--rowGap));
}

.categories-view {
    width: 100%;
    .grid();
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: var(--prodGapVert) 30px;

    a {
        width: 100%;
        color: @header-color;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        padding: 27px;
        padding-bottom: 16px;
        border: 1px solid @sec-color;
        .grid();
        grid-template-rows: auto auto;
        gap: 24px;
        align-items: center;

        @media(min-width: 1280px) {
            &:hover {
                text-decoration: underline;
            }
        }

        figure {
            width: 100%;
            aspect-ratio: 1;

            img {
                .img-crop();
            }
        }

        span {
            height: 100%;
        }
    }
}

@media(max-width: 1680px) {
    .categories-view {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media(max-width: 1279px) {
    .categories-view-wrapper {
        gap: var(--rowGap);
    }

    .categories-view {
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: var(--prodGapHor);

        a {
            padding: 10px !important;
            font-size: 12px;
        }
    }
}

@media(max-width: 912px) {
    .categories-view {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

@media(max-width: 732px) {
    .categories-view {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (max-width: 552px) {
    .categories-view {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (max-width: 372px) {
    .categories-view {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}


#content:has(.categories-view) .categories-header-wrapper {
    margin-bottom: 0 !important;
}

// LISTING WRAPPER
.listing-wrapper {
    .grid();
    grid-template-columns: minmax(0, 1fr);
    gap: 56px;
}

// CATEGORY HEADER & DESCRIPTION
.category-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .headline {
        margin-bottom: 0 !important;
    }

    &:not(:has(> *)) {
        display: none;
    }

    &:not(:empty) {
        margin-bottom: -22px;
    }
}

.second-listing-desc {
    order: 1;

    &:empty {
        display: none;
    }
}

// NAVIGATION
.navigation-wrapper {
    display: none;
    flex-wrap: wrap;
    gap: 20px;

    &:has(#pills-container .single-pill),
    &:has(.dropdown-picker) {
        display: flex;
    }
}

.listing-wrapper:not(:has(.product)) {

    .navigation-wrapper,
    .dropdown-picker {
        display: none;
    }
}

#pills-container {

    &:empty {
        display: none;
    }

    &:not(:empty) {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 100%;
        gap: 16px 20px;
    }
}

.listing-button {
    z-index: 10;
    border-radius: inherit;
    position: relative;
    text-align: left;
    border: 1px solid @border-color;
    color: @font-color;
    height: 48px;
    padding: 0 48px 0 20px;
    margin-top: 15px;

    &:before {
        position: absolute;
        top: -13px;
        left: 12px;
        background-color: white;
        padding: 2px 8px;
        color: @header-color;
        font-size: 12px;
        font-weight: 700;
        z-index: 11;
        pointer-events: none;
        text-transform: uppercase;
        letter-spacing: 0.08em;
    }

    &:after {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        .small-icon();
        color: @header-color;
        content: '\e800';
        font-size: 12px;
    }

    &:hover {
        border-color: @header-color;
    }

    &.filter-trigger {
        display: none;
        align-items: center;
        min-width: 197px;

        &:before {
            content: 'Filtrowanie';
        }

        &:after {
            right: 10px;
            content: "\e913";
            font-size: 20px;
        }
    }

    &.sorting {
        min-width: 262px;

        &:before {
            content: 'Sortowanie';
        }
    }

    &.page-size {
        min-width: 185px;

        &:before {
            content: 'Ilość';
        }
    }
}

.dropdown-picker {
    position: relative;
    display: flex;
    z-index: 10;
    border-radius: inherit;

    &.show-dropdown {

        .dropdown-button:after {
            transform: scaleY(-1);
            top: calc(50% - 4px);
        }

        .dropdown-listing {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}

.dropdown-listing {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid @border-color;
    border-top: 0;
    .shadow();
    min-width: 100%;
    padding: 7px 0;
    transform: translateY(-5px);
    transition: all .2s ease-in-out;
    border-radius: inherit;

    input {
        display: none;

        &:checked + label {
            font-weight: 700;
            color: @header-color;
            text-decoration: none !important
        }
    }

    span {
        display: block;
    }

    label {
        display: block;
        padding: 6px 20px;
        user-select: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

// FILTER
.close-filter,
.close-filter-bottom {
    display: none;
}

body[data-canvas-filter] {

    &:has(#filter .gs-filtr-container) {

        .navigation-wrapper {
            display: flex;
        }

        .filter-trigger {
            display: flex;
        }
    }
}

.filter-sorter-wrapper {
    border: 4px solid @border-color;
    border-top: 0;
    border-bottom-width: 1px;
    padding: 24px 17px;

    .sorter {

        &:before {
            display: none;
        }
    }

    .sorter-button {
        min-width: 0;
        width: 100%;
    }
}

@media (min-width: 1280px) {
    // FILTER ON CANVAS
    body[data-canvas-filter] {

        #filter {
            position: fixed;
            top: 0;
            transform: translateX(-100%);
        }
    }
}

@media (min-width: 455px) {
    .products-list.list-view {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    // LIST VIEW TRIGGERS
    .view-type-wrapper {
        margin-right: auto;
        display: flex;
        gap: 24px;
        position: relative;
        height: 48px;
        margin-top: 15px;

        &:before {
            content: "Widok produktów";
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.08em;
            font-size: 12px;
            position: absolute;
            top: -13px;
            left: 0;
            white-space: nowrap;
        }

        button {
            display: flex;
            gap: 10px;
            align-items: center;

            > span {
                width: 25px;
                height: 24px;
            }

            i {
                border: 1px solid rgba(36,22,35, 0.6);
            }

            &:hover {

                i {
                    border-color: @main-color;
                }
            }
        }

        .view-list > span {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            i {
                width: 100%;
                height: 6px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .view-grid {
            font-weight: 700;

            i {
                border-color: @main-color;
            }

            > span {
                .grid();
                grid-template-columns: repeat(2, 10px);
                gap: 2px;

                i {
                    width: 100%;
                    aspect-ratio: 1;
                }
            }
        }
    }

    #content:has(.list-view) {

        .view-type-wrapper {
            gap: 22px;
        }

        .view-list {
            font-weight: 700;

            i {
                border-color: @main-color;
            }
        }

        .view-grid {
            font-weight: normal;
            gap: 12px;

            i {
                border-color: @header-color;
            }
        }
    }

    // LIST VIEW PRODUCTS
    .list-view .product {
        grid-template-columns: 220px minmax(0, 1fr) auto;
        grid-template-rows: initial;
        align-items: stretch;
        border: 1px solid @border-color;
        padding-inline: 24px;
        gap: 40px;

        .product-image {
            width: 100%;
            height: initial;
            aspect-ratio: ~"220/145";
            align-self: center;
            padding-block: 0;
        }

        .middle-info {
            .grid();
            grid-template-rows: auto auto 1fr;
            gap: 8px;
            z-index: 1;
            padding-block: 24px;

            .product-name {
                text-align: start;
            }

            .product-hover {
                display: contents;
            }

            p {
                transform: none !important;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                display: block;
                display: -webkit-box !important;
                font-size: 14px;
                --fontSize: 14px;
                max-height: ~"calc(4 * (var(--fontSize) * var(--lineHeight)))";
                overflow: hidden;
                text-overflow: ellipsis;
            }

            i {
                display: block;
                grid-row: 3 / 4;
                align-self: end;
                height: auto;
                font-style: normal;
                margin-top: 28px;
            }
        }

        .product-bottom-wrapper {
            border: initial;
            border-left: 1px solid @border-color;
            align-self: stretch;
            padding-block: 24px;
            padding-inline: 40px 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;

            .netto-price {
                margin-top: -6px;
            }

            .product-add {
                margin-top: auto;
                padding: 8px 20px;
                align-self: center;
            }
        }
    }
}

// IE FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sorter-button:before {
        display: none;
    }
}